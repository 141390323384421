<template>
  <div class="helpView">
    <van-sticky>
      <van-nav-bar left-arrow @click-left="onClickLeft" :title="$t('帮助手册')" />
    </van-sticky>
    <van-list v-model="loading" :finished="finished" :finished-text="$t('没有更多了')" loading-text="..." @load="onLoad">
      <van-cell v-for="(item, key) in list" :key="key" class="xiax_item" @click="onDetail(item)">
        <div class="left">
          <span class="desc">【{{ $store.getters.userinfo.web_name }}】{{ item.title }}</span>
        </div>
        <div class="right">
          <van-icon size="0.5rem" name="arrow" />
        </div>
      </van-cell>
    </van-list>
    <van-popup v-model="show" position="bottom" :style="{ height: '1000%' }">
      <van-sticky>
        <van-nav-bar left-arrow @click-left="onClickHide" />
      </van-sticky>
      <div class="content" v-if="detail.title">
        <p class="title">{{ detail.title }}</p>
        <p>
          <br />
        </p>
        <div style="line-height: 0.5rem;" v-html="detail.content"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getHelpLists } from '@/api/help';
export default {
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 15,
      loading: false,
      finished: false,
      show: false,
      detail: {},
    };
  },
  methods: {
    onClickHide() {
      this.show = false;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onDetail(data) {
      this.detail = data;
      this.show = true;
    },
    //获取公告列表
    GetNoticeList() {
      getHelpLists({
        page: this.page,
        pageSize: this.pageSize,
        lang: this.$lang,
      })
        .then((response) => {
          this.list.push.apply(this.list, response.result);
          // 加载状态结束
          this.loading = false;
          this.page++;
          if (response.result.length == 0) {
            this.finished = true;
          }
        })
        .catch((error) => {});
    },
    onLoad() {
      this.GetNoticeList();
    },
  },
};
</script>
<style lang="stylus">
.helpView
  .van-list
    margin-top: 0.3rem
    .xiax_item
      display: flex
      padding: 0.3rem
      background-color: #151d31
      margin-bottom: 0.05rem
      font-weight: 500
      .van-cell__value
        display: flex
        .left
          flex: 1
          color: #777
          font-size: 0.36rem
          padding: 6px
          .desc
            color: #bbb
            font-size: 0.4rem
        .right
          color: $grayTextColor
          display: flex
          align-items: center
          justify-content: center
  .content
    color: #ffffff
    font-size: 0.38rem
    padding: 16px 20px
    .title
      text-align: center
</style>
