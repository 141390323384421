import request from '@/utils/request';
import Vue from 'vue';

export function getHelpLists(data) {
  return request({
    url: '/help/lists?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
